import React from "react";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import InvoiceModal from "../../../../Modals/InvoiceModal/InvoiceModal"

import API from "../../../../../firebase/api";
import * as styles from "./payments.module.scss"


export default () => {

    const [invoiceData, setInvoiceData] = React.useState(null);
    const [loader, setLoader] = React.useState(true);
    const [response, setResponse] = React.useState(null);

    React.useEffect(() => {
        async function paymentsInfo() {
          let response = await API.getUserPayments();
          setResponse(response)
        }
        paymentsInfo();
    }, []);

    function processAmount(num){
        var num = Number(num) / 100;
        // If not a number, return 0
        if (isNaN(num)) {
            return 0;
        }
        // If there is no decimal, or the decimal is less than 2 digits, toFixed
        if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
            num = num.toFixed(2);
        }
        // Return the number
        return num;
    }

    function handleInvoiceView(payment, billing_info) {
        let data = {
            email: billing_info.email,
            billTo: billing_info.bill_to,
            paymentId: payment.id,
            paymentAmount: payment.amount_received,
            created_ts: payment.created_ts,
            amount: processAmount(payment.amount_received),
            currency: payment.currency
        }
        setInvoiceData(data)
    }

    function renderPayments(response){
        if (response==null) {
            return (
                <BeatLoader />
            )
        } 

        if (response.payments.length == 0){
            return (
                <div>
                    <p>No payments found.</p>
                </div>
            )
        } else {
            let billing_info = response.billing_info
            return (
            <table>
                <thead>
                    <tr>
                        <th>
                            Date
                        </th>
                        <th>
                            Amount
                        </th>
                        <th>
                            Invoice
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {response.payments.map(payment => (
                        <tr>
                            <td>{new Date(payment.created_ts).toLocaleString('en-US',
                            {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            }
                        )}</td>
                            <td>{processAmount(payment.amount_received)}</td>
                            <td><button onClick={() => handleInvoiceView(payment, billing_info)} className={styles.purpleButton}>View</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )
        }
    }

    return (
        <div className={styles.container}>
            <h2>Payments</h2>
            {renderPayments(response)}
            <InvoiceModal 
            open={invoiceData? true : false}
            paymentId={invoiceData?.paymentId}
            amount={invoiceData?.amount}
            email={invoiceData?.email}
            billTo={invoiceData?.billTo}
            created_ts={invoiceData?.created_ts}
            currency={invoiceData?.currency}
            setInvoiceData={setInvoiceData}/>
        </div>
    )
}