import React from 'react';
import Logo from '../../../assets/svg/logo.svg';
import Modal from '../Modal/Modal';
import * as styles from './invoicemodal.module.scss';



export default ({open, email, billTo, amount, created_ts, paymentId, currency, setInvoiceData}) => {

    function processBillTo(billTo){
        return (
            <div>
            </div>
        )
    } 

    const handleClose = () => {
        setInvoiceData(null)
    };

  return (
      <Modal open={open} handleClose={handleClose}>
          <div className={styles.container}>
              <div className={styles.header}>
                  <div className={styles.invoice}>
                      <h2>Invoice</h2>
                      <ul>
                        <li>
                              ID: {paymentId}
                        </li>
                        <li>
                              Date: {new Date(created_ts).toUTCString()}
                        </li>
                      </ul>
                  </div>
              </div>

              <div>
                  <h3>From:</h3>
                  <ul>
                      <li>
                        <div className={styles.logo}>
                            <Logo />
                        </div>    
                      </li>
                      <li>hello@previewed.app</li>
                      <li>1309 Coffeen Avenue, Sheridan, Wyoming, 82801</li>
                  </ul>
              </div>
              <div>
                  <h3>Bill to:</h3>
                  <ul>
                      <li>{email}</li>
                      {billTo}
                  </ul>
              </div>
              <div>
                  <h3>Summary</h3>
                  <table>
                    <thead>
                        <tr>
                            <th>
                                Description
                            </th>
                            <th>
                                Price
                            </th>
                            <th>
                                Currency
                            </th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Access to Previewed.app</td>
                        <td>{amount}</td>
                        <td>{currency}</td>
                    </tr>
                  </table>
                  <p>Total ({currency}): {amount}</p>
              </div>
          </div>
      </Modal>
  );
}