import React from "react";
import Layout from "../components/layout"
import Billing from "../components/Pages/Account/Billing/Billing";
import PrivateRoute from "../components/PrivateRoute";
import { Router } from "@reach/router"

export default () => {
  return <Layout>
    <Router basepath="/billing">
        <PrivateRoute component={Billing} path="/"/>
    </Router>
</Layout>
};
