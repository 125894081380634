import React from "react";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

import CustomOptionsModal from '../../../Modals/CustomOptionsModal/CustomOptionsModal'
import SideMenu from "../AccountSideMenu/AccountSideMenu";
import API from "../../../../firebase/api";
import * as styles from "./billing.module.scss"
import Payments from "./Payments/Payments"


export default () => {

    const [loader, setLoader] = React.useState(false);
    const [credits, setCredits] = React.useState(null);
    const [subscription, setSubsciption] = React.useState(null);
    const [cancelModal, setCancelModal] = React.useState(false)

    React.useEffect(() => {
        async function accountInfo() {
          let info = await API.canUserDownload()
          let subscriptions = await API.getUserSubscription()
          setSubsciption(subscriptions[0])
          setCredits(info.unlimited ? 'Unlimited' : info.credits)
        }
        accountInfo();
    }, []);


    function renderSubscriptionBlock(subscription) {
        if (subscription) {
            if (subscription.status=="active" || subscription.status === "trialing") {
                let button_text = loader ? (<BeatLoader color={'#ffffff'} loading={true} size={10} />) : 'Cancel Subscription'

                return (
                    <div>
                        <p>Status: <span className={styles.active}>{subscription.status}</span></p>
                        <p>Payment Amount: ${subscription.price_amount / 100}</p>
                        <p>Next Payment: {new Date(subscription.current_period_end).toUTCString()}</p>
                        <button className={styles.blackButton} onClick={showCancelModal}>{button_text}</button>
                    </div>
                )
            }
            else if (subscription.status=="canceled") {
                let button_text = 'Upgrade your plan'
                return (
                    <div>
                        <p>Status: <span className={styles.canceled}>{subscription.status}</span></p>
                        <p>Valid Until: {new Date(subscription.current_period_end).toUTCString()}</p>
                        <button className={styles.blackButton} onClick={() => navigate('/plans')}>{button_text}</button>
                    </div>
                )
            }
            else {
                let button_text = loader ? (<BeatLoader color={'#ffffff'} loading={true} size={10} />) : 'Cancel Subscription'
                return (
                    <div>
                        <p>Status: <span className={styles.canceled}>{subscription.status}</span></p>
                        <p>Period End: {new Date(subscription.current_period_end).toUTCString()}</p>
                        <button onClick={showCancelModal}>{button_text}</button>
                    </div>
                )
            }
        } 
        else {
            return (
                <div>
                    <p>No active subscription</p>
                    <button className={styles.blackButton} onClick={() => navigate('/plans')}>Upgrade your plan</button>
                </div>
            )
        }
    }

    async function cancelSubscription() {
        setLoader(true)
        setCancelModal(false)
        let response = await API.cancelSubscription(subscription.id)
        if(response){
          setSubsciption(response)
          toast.success(`✔ Subscription Canceled`)
        }
        else {
          toast.error('Unable to cancel. Please try again later')
        }
        setLoader(false)
    }

    function showCancelModal() {
        setCancelModal(true)
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <SideMenu />
            </div>
            <div className={styles.right}>
            {cancelModal ?
                <CustomOptionsModal title={"Are you sure?"}
                subtitle={"You will immediately lose all benefits of the subscription."}
                successText={'Yes, cancel'}
                cancelText={"No"} 
                onSuccess={cancelSubscription} 
                onCancel={() => setCancelModal(false)}
                /> : 
            null}
                <h1>
                    Billing
                </h1>
                <h2>Export Credits</h2>
                <p>You have {credits} export credits. Weekly free credit is not included.</p>
                <h2>Subscription</h2>
                <div className={styles.subscription}>
                    {renderSubscriptionBlock(subscription)}
                </div>
                <Payments />
            </div>
        </div>
    )
}